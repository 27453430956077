<template>
  <div>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Penggajian'">
          <template v-slot:body>
            <div class="row align-items-center">

              <!-- Profile Image -->
              <div class="col-md-4">
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div style='height: 350px; width: 350px; position: relative'>
                    <img
                      class="image"
                      src="/images/expense-splash.svg"
                      
                    >
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div
                class="col"
               
              >
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>Nama</strong></td>
                    <td>{{ data.employee_name }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Jumlah</strong></td>
                    <td>{{ data.ammount }}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Remunerasi</strong></td>
                    <td>
                      <div v-for="(name,index) in data.remuneration_type_name" :key="index">
                        <li>
                            {{data.remuneration_type_name[index]}}
                        </li>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 25%"><strong>Metode Pembayaran</strong></td>
                    <td>{{ data.payment_name }}</td>
                  </tr>
                </table>

                <button
                  @click="$router.push({path: '/payrolls/Edit/' + data.id})"
                  class="btn mx-1 btn-success"
                  v-if="manipulateBtn == true"
                ><i class="far fa-fw fa-edit"></i> Edit</button>
                <button
                  @click="deleteData"
                  class="btn mx-1 btn-danger"
                  v-if="manipulateBtn == true"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button>
              </div>

            </div>
          </template>
        </Card>
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      // Data
      data:[],
      // Other
      imgLoaded: false,
      manipulateBtn:false
    }
  },

  components: {
    Card,
  },

  methods: {

    onImageLoad() {
      this.imgLoaded = true
    },

    async deleteData() {
      let result = await module.delete('payrolls/' + this.$route.params.id)
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push('/payrolls/list')
      }
    },

    async get() {
      this.data = await module.get('payrolls/' + this.$route.params.id)
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/payrolls/list')
      }
    },

    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      console.log("looping")
      
      if(access_right[a] == "7004"){
        this.manipulateBtn = true
      }
      
    }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Penggajian", route: "" },
      { title: "Daftar Penggajian", route: "/payrolls/list" },
      { title: "Detail" },
    ])
    // Get Data
    this.get()
    this.setActiveMenu()
  },
}

</script>